import { useState } from "react";
import ContextCreate, { ContextInterface, UserInterface } from "Interfaces/Context";
import { ENVIRONMENT } from "Service/environment";
import { post } from "Service/Api";

interface PropsInterface {
    children: React.ReactNode;
}
const Context: React.FC<PropsInterface> = ( props: PropsInterface ) =>{
    // const navigate = useNavigate();
    const [user, setUser] = useState<UserInterface>({
        id: "",
        name: "",
        lastname: "",
        email: "",
        token: "",
        codeRol: "",
        nameRol: "",
        auth: false
    })
    const [notification, setNotification] = useState<any[]>([])

    const assignateUser = (user: UserInterface) => {
        setUser(user)
        localStorage.setItem("token", user.token)
    }

    const closeSession = async (  callBack: () => void, reject : () => void ) => {
        await post({
            url: ENVIRONMENT.API + ENVIRONMENT.ENDPOINTS.LOGOUT,
            callback: (response: any) => {
                localStorage.removeItem("token")
                setUser({
                    id: "",
                    name: "",
                    lastname: "",
                    email: "",
                    token: "",
                    codeRol: "",
                    nameRol: "",
                    auth: false
                })
                callBack()
                return response.status

            },
            reject: (error: any) => {
                reject()
                return error?.response?.status ?? 500

            }
        })
       
    }

    const assignateNotification = (notificationData:any[] ) => {
        setNotification(notificationData)
    }

    const context: ContextInterface = {
        user,
        notification,
        assignateNotification,
        assignateUser,
        closeSession
    }

    return (
        <ContextCreate.Provider value={ context }>
            { props.children }
        </ContextCreate.Provider>
    )
}
export default Context;