export const ENVIRONMENT = {
    API: "https://inventory-api.benjadevsoft.com",
    ENDPOINTS: {
        LOGIN: "/auth/login",
        LOGOUT: "/auth/signout",
        CATEGORIES: "/api/categories",
        SUPPLIER: "/api/client",
        VALIDATEAUTH: "/auth/validate/token",
        STORES: "/api/stores",
        MESURE: "/api/mesure",
        TYPEINVENTORY: "/api/typeinventory",
        INVENTORY: "/api/inventory",
        PRODUCTS: "/api/product",
        TURNS: "/api/turns",
    }
}