import { Suspense, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";

const Dashboard = lazy(()=> import("Pages/Dashboard"))
const Login = lazy(()=> import("Pages/Login"))
const Home = lazy(()=> import("Pages/Home"))
const NotFound = lazy(()=> import("Pages/NotFound"))
const ListCategories = lazy(()=> import("Pages/ListCategories"))
const ListSupplier = lazy(()=> import("Pages/ListSupplier"))
const Inventory = lazy(()=> import("Pages/Inventory"))
const Index = lazy(()=> import("Pages/Index"))
const Profile = lazy(()=> import("Pages/Profile"))

interface PropsInterface {
    children?: React.ReactNode
}
const RenderElement =( props: PropsInterface ) => {
    const { children } = props
    return(
        <Suspense fallback={<p>Loading.....</p>}>
            { children }
        </Suspense>
    )
}

const Router = [
    {
        path: "/",
        element: <Navigate  to="/home/index" replace={true} />,
        errorElement: <div>404</div>,

    },{
        path: "login",
        element: <RenderElement children={<Login/>}/>,
        errorElement: <div>404</div>,
    },
    {
        path: "home",
        element:  <RenderElement children={<Index />}/>,
        children: [
            {
                path: "index",
                element: <RenderElement children={<Home />}/>,
            },{
                path: "dashboard",
                element: <RenderElement children={<Dashboard />}/>,
            },{
                path: "inventory",
                element: <Outlet/>,
                children: [
                    {
                        path: "",
                        element:  <RenderElement children={<Inventory />}/>,
                    }
                    // ,{
                    //     path: "categories",
                    //     element:  <RenderElement children={<ListCategories />}/>,
                    // },{
                    //     path: "suppliers",
                    //     element:  <RenderElement children={<ListSupplier />}/>,
                    // }
                ]
            },{
                path: "profile",
                element:  <RenderElement children={<Profile />}/>,
            },{
                path: "supplier",
                element: <RenderElement children={<ListSupplier />}/>,
            },{
                path: "*",
                element:  <RenderElement><NotFound /></RenderElement>,
            }
        ]
    }, {
        path: "*",
        
    }
]

export default Router;