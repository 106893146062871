import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Router from "Router/Router";
import "Styles/index.scss";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const App: React.FC = () => {
    const router = createBrowserRouter(Router);
    return (
        <RouterProvider router={router} />
    );
}

export default App;
