import axios from "axios"
import { GetInterface, PostInterface } from "Interfaces/Api"

const post = ( Props: PostInterface ) => {
    let { url, setLoader, body, headers, callback, setMessage, reject } = Props

    if (setLoader !== undefined) setLoader( true )
    axios.post( url, body, headers ).then( response => {
            if (setLoader !== undefined) setLoader( true )
            if ( callback !== undefined ) callback( response )
        } )
        .catch( error => {
            if (setLoader !== undefined) setLoader( true )
            if ( setMessage !== undefined ) {
                setMessage({
                    text: error?.response?.data?.error ?? "No se pudo realizar la accion solicitada",
                    open: true,
                    type: "error"
                })
            } 
            if ( reject !== undefined ) reject(error)
        } )
}


const get = ( Props: GetInterface ) => {
    const { url, setLoader, headers, callback, setData } = Props

    setLoader( true )
    axios.get( url, headers ).then( response => {
        if ( setData ) {
            setData(response.data.data ?? response.data)
        }
        if ( callback !== undefined ){
            callback(response)
        }
        setLoader( false )

    } )
    .catch( error => {
        setLoader( false )
        console.log(error?.response?.data)
        // setMessage({
        //     text: error?.response?.data?.error ?? "No se pudo realizar la accion solicitada",
        //     open: true,
        //     type: "error"
        // })
    } )
}
export {
    post,
    get
}