import { createContext } from "react";
export interface UserInterface {
    id: string;
    name: string;
    email: string;
    lastname: string;
    token: string;
    codeRol: string;
    nameRol: string
    auth: boolean;
}

export interface ContextInterface {
    user: UserInterface;
    notification: any[]
    assignateNotification:(notification: any[]) =>  void
    assignateUser: (user: UserInterface) => void;
    closeSession: ( callBack: () => void, reject: ()=> void) => void
}

const ContextCreate = createContext<ContextInterface>({
        user: {
            id: "",
            name: "",
            email: "",
            lastname: "",
            token: "",
            codeRol: "",
            nameRol: "",
            auth: false
            
        },
        notification: [],
        assignateNotification: (notificationData) => {}, 
        assignateUser: (user: UserInterface) => {},
        closeSession: ( callBack: () => void, reject: ()=> void ) => {}
    }
);

export default ContextCreate;